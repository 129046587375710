import NavigationEnum from 'common/navigation';
import { styles } from 'modules/auth/style';
import { useRouter } from 'next/router';
import React from 'react';

import DefaultLayout, { DefaultLayoutProps } from './default-layout';

export { default as DefaultLayout } from './default-layout';

export const publicnessRoute: Record<NavigationEnum, boolean> = {
  [NavigationEnum.aboutUs]: true,
  [NavigationEnum.checkBookingList]: false,
  [NavigationEnum.forgotPassword]: true,
  [NavigationEnum.guestAddPassenger]: false,
  [NavigationEnum.guestAddVehicle]: false,
  [NavigationEnum.guestList]: false,
  [NavigationEnum.guestPassengerList]: false,
  [NavigationEnum.guestVehicleList]: false,
  [NavigationEnum.home]: true,
  [NavigationEnum.login]: true,
  [NavigationEnum.maintenance]: true,
  [NavigationEnum.notFound]: true,
  [NavigationEnum.profile]: false,
  [NavigationEnum.refund]: false,
  [NavigationEnum.register]: true,
  [NavigationEnum.reschedule]: false,
  [NavigationEnum.searchShipping]: true,
  [NavigationEnum.shippingAddons]: true,
  [NavigationEnum.shippingDetail]: false,
  [NavigationEnum.shippingPayment]: false,
  [NavigationEnum.termCondition]: true,
};

export default function Layout({ children }: { children: React.ReactNode }) {
  const { pathname } = useRouter();

  const defaultLayoutProps = React.useMemo<
    Omit<DefaultLayoutProps, 'children'>
  >(() => {
    switch (pathname as NavigationEnum) {
      case NavigationEnum.login:
      case NavigationEnum.forgotPassword:
        return {
          mainClassName: styles.mainClass({ css: { height: '100%' } }),
        };
      case NavigationEnum.aboutUs:
        return {
          mainCss: {
            height: '100%',
          },
        };
      case NavigationEnum.guestAddPassenger:
      case NavigationEnum.guestAddVehicle:
        return {
          isShowFooter: true,
        };
      case NavigationEnum.guestList:
      case NavigationEnum.guestPassengerList:
      case NavigationEnum.guestVehicleList:
      case NavigationEnum.searchShipping:
      case NavigationEnum.termCondition:
        return {
          isShowFooter: true,
          isShowFloatingButton: true,
        };
      case NavigationEnum.checkBookingList:
        return {};
      case NavigationEnum.shippingAddons:
        return {
          mainCss: {
            height: '100vh',
            overflowY: 'auto',
          },
        };
      case NavigationEnum.profile:
        return {
          mainCss: { flex: 1 },
        };
      case NavigationEnum.shippingDetail:
      case NavigationEnum.shippingPayment:
      case NavigationEnum.reschedule:
      case NavigationEnum.refund:
      case NavigationEnum.home:
        return {
          isShowBottomSheet: true,
          isShowFloatingButton: true,
          isShowFooter: true,
        };

      case NavigationEnum.maintenance:
      case NavigationEnum.register:
      case NavigationEnum.notFound:
      default:
        return {};
    }
  }, [pathname]);

  switch (pathname as NavigationEnum) {
    case NavigationEnum.maintenance:
    case NavigationEnum.register:
    case NavigationEnum.notFound:
      return children;
    default:
      return <DefaultLayout {...defaultLayoutProps} children={children} />;
  }
}
